import { FC } from 'react'
import { CardHeader, Grid, Typography } from '@mui/material'

import { CardContainer } from '../../elements'
import {
  List,
  ListItem,
  PriceContainer,
  OptionContent,
  SelectButton,
} from 'features/manage-plan/components/plan-options/elements'

interface Props {
  name: string
  features: string[]
  price?: string
  isCurrent: boolean
  isNextPlan: boolean
  handleSelectedPlan: () => void
}

export const PlanOption: FC<Props> = ({ name, features, price, isCurrent, isNextPlan, handleSelectedPlan }) => (
  <Grid item xs={12} sm={6} md={4}>
    <CardContainer $highlightBorder={isNextPlan}>
      <OptionContent>
        <CardHeader title={name} titleTypographyProps={{ variant: 'h5', fontWeight: 500 }} />

        <SelectButton
          variant={isCurrent ? 'text' : 'contained'}
          disabled={isCurrent}
          color="primary"
          onClick={handleSelectedPlan}
        >
          {isCurrent ? 'Current Plan' : 'Select'}
        </SelectButton>

        <List>
          {features.map((text) => (
            <ListItem key={`${name}-${text}`}>
              <Typography variant="body2">{text}</Typography>
            </ListItem>
          ))}
        </List>

        <PriceContainer>
          <Typography variant="h5" fontWeight={500}>
            {`$${price}/mo`}
          </Typography>
        </PriceContainer>
      </OptionContent>
    </CardContainer>
  </Grid>
)
