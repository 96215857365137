/**
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */
export const invoiceDataKey = 'invoiceData'
export const sectionKey = 'section'
export const productsKey = 'products'
export const marketplaceSectionsKey = 'marketplaceSections'
export const activeAdCampaignInSubscriptionKey = 'activeAdCampaignInSubscription'
export const subscriptionsKey = 'subscriptions'
export const productKey = 'product'
export const adCampaignKey = 'adCampaign'
export const campaignManagerProductSelectKey = 'campaignManagerProductSelect'
export const campaignManagerSelectedProductKey = 'campaignManagerSelectedProductKey'
export const customContentArticlesCacheKey = 'custom-content-article-orders'
export const customContentSeriesCacheKey = 'custom-content-series-orders'
export const collectionsCacheKey = 'collections'
export const accountPaymentsCacheKey = 'accountPayments'
export const ampTemplatesCacheKey = 'ampTemplates'
export const pubUsersKey = 'pubUsers'
