/**
 * @file Privacy Policy page
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React from 'react'
import { Helmet } from 'react-helmet-async'
// MUI Components
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
// Internal
import SectionHeader from '../components/SectionHeader'
import { useRegisterPageView } from 'hooks/useRegisterPageView'
import { withOrigin } from '../utils'
import policy from '../utils/privacyPolicy.json'

const extraText = {
  choices: (
    <span>
      For information about how to manage information about you and the choices you have, see the <b>Your Choices</b>{' '}
      section below.
    </span>
  ),
  subChoices: (
    <span>
      See the <b>Your Choices</b> section below for more information.
    </span>
  ),
  links: (
    <span>
      The information generated by Google Analytics will be transmitted to and stored by Google and will be subject to
      Google’s{' '}
      <a href="http://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">
        privacy policies
      </a>
      . To learn more about Google’s partner services and to learn how to opt out of tracking of analytics by Google
      click{' '}
      <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noreferrer">
        here
      </a>
      .
    </span>
  ),
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.marketplacePrimary,
    height: theme.spacing(7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: theme.spacing(4),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  padded: {
    paddingLeft: theme.spacing(2),
  },
  header: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  space: {
    paddingTop: theme.spacing(2),
  },
}))

const PrivacyPolicy = (props) => {
  const { marketplace, logoPath } = props
  const classes = useStyles(props)

  useRegisterPageView('/privacy-policy', 'Privacy Policy')

  return (
    <>
      <Helmet>
        <title>Privacy Policy | {marketplace}</title>
      </Helmet>

      <div className={classes.appBar}>
        <img src={withOrigin(logoPath)} className={classes.logo} alt={logoPath} />
      </div>

      <section>
        <div className={classes.title}>
          <SectionHeader label="PRIVACY STATEMENT" />
        </div>

        <div className={classes.padded}>
          <Typography className={classes.header}>
            <u>Effective Date</u>: 08/10/2021
          </Typography>
          <br />
          <Typography>
            Distributed Media Lab, Inc., including any affiliates and subsidiaries (“DML,” “we,” “us,” or “our”) values
            the trust you place in us when you use our affiliated websites, applications, and online services
            (collectively, the “Services”). The Services are directed to business clients and this Privacy Statement
            describes our collection, use, disclosure, and retention of information we collect from or about the
            representatives or users of these business clients. This Privacy Statement applies to our Services that post
            or link to it, regardless of how you access or use them, including through mobile devices.
          </Typography>
          <br />
          <Typography>
            <b>
              If you are consumer that interacts with one of our publisher clients, please see our{' '}
              <a href="https://www.distributedmedialab.com/privacy-policy/" target="_blank" rel="noreferrer">
                Consumer Privacy Statement
              </a>
              .
            </b>
          </Typography>
          <Typography>
            BY USING OUR SERVICES, YOU ARE ACCEPTING THE TERMS OF THIS PRIVACY STATEMENT AND OUR{' '}
            <a href="/terms-of-service" target="_blank">
              TERMS OF USE
            </a>
            , AND YOU ARE CONSENTING TO OUR COLLECTION, USE, DISCLOSURE, AND RETENTION OF INFORMATION AS DESCRIBED IN
            THIS PRIVACY STATEMENT AND REPRESENT THAT YOU ARE AUTHORIZED TO DISCLOSE THE PROVIDED INFORMATION BY YOUR
            PRINCIPAL. If you do not agree to the terms of this Privacy Statement, please do not use our Services. We
            may make changes to this Privacy Statement from time to time. We will post any changes to our Services. Your
            continued use of our Services following the posting of any changes will mean you accept those changes.
          </Typography>
          <br />
          <Typography>
            This Privacy Statement does not apply to information that you may submit to third-party websites or
            applications that may link to the Services or be linked to on the Services. We are not responsible for the
            actions or privacy practices of third-party services; please consult those third parties directly to
            understand their privacy practices.
          </Typography>
        </div>

        {policy.map(({ title, text, specialText, sections = [] }) => (
          <div key={title}>
            <Typography variant="h5" className={classes.header}>
              {title}
            </Typography>
            {!!text && (
              <Typography className={classes.padded}>
                {specialText ? (
                  <div>
                    {text} {extraText[specialText]}
                  </div>
                ) : (
                  text
                )}
              </Typography>
            )}

            {sections.map(({ sectionTitle, sectionText, subSpecialText }) => (
              <div key={`${title}-${sectionTitle}`}>
                <Typography className={classes.header}>{sectionTitle ? `${sectionTitle}:` : ''} </Typography>
                <Typography className={classes.padded}>
                  {subSpecialText ? (
                    <div>
                      {sectionText} {extraText[subSpecialText]}
                    </div>
                  ) : (
                    sectionText
                  )}
                </Typography>
              </div>
            ))}
          </div>
        ))}

        <div className={classes.padded}>
          <Typography className={classes.space}>
            Many third-party advertisers are members of programs that offer you additional choices regarding the use of
            information about you for advertising. You can learn more about the options available to limit these third
            parties’ use of your information by visiting the Services for the{' '}
            <a href="http://www.networkadvertising.org/choices/" target="_blank" rel="noreferrer">
              Network Advertising Initiative
            </a>{' '}
            and the{' '}
            <a href="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer">
              Digital Advertising Alliance
            </a>
            . Similarly, you can learn about your options to opt-out of mobile app tracking by certain advertising
            networks through your device settings. For information about how to change these settings, see the support
            information for{' '}
            <a href="https://support.apple.com/en-us/HT205223" target="_blank" rel="noreferrer">
              Apple
            </a>
            ,{' '}
            <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noreferrer">
              Android
            </a>
            , or{' '}
            <a href="https://account.microsoft.com/privacy/ad-settings/" target="_blank" rel="noreferrer">
              Windows
            </a>{' '}
            devices.
          </Typography>
          <Typography className={classes.space}>
            Please note that opting-out of participating advertising networks services does not mean that you will not
            receive advertising, nor will it prevent the receipt of interest-based advertising from third parties that
            do not participate in these industry programs.
          </Typography>
        </div>

        <Typography variant="h5" className={classes.header}>
          OTHER IMPORTANT INFORMATION
        </Typography>
        <Typography className={classes.header}>Users Outside of the United State:</Typography>
        <Typography className={classes.padded}>
          If you use our Services outside of the United States, you understand and consent to the transfer of
          information we collect from or about you to, and the collection, processing, and storage of information about
          you in, the United States and elsewhere. The laws in the U.S. and these countries may be different than the
          laws of your country.
        </Typography>

        <Typography className={classes.header}>
          <b>For questions about our Privacy Statement, you can contact us at info@distributedmedialab.com.</b>
        </Typography>

        <Typography className={classes.space}>All Rights Reserved.</Typography>
      </section>
    </>
  )
}

export default PrivacyPolicy
