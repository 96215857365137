/**
 * @file Top level app config provider
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { createContext, useState, useEffect, useMemo } from 'react'
import { getMarketplaceMeta } from '@dmlab/ui-api-marketplaces'
import { useQuery } from 'react-query'
import axios from 'axios'
import find from 'lodash/find'
import qs from 'querystring'
import { defaultsDeep, get } from '../utils/lodash'
import defaultProductTypeConfig from '../utils/productTypeConfigs.json'
import FullscreenBlinker from '../components/FullscreenBlinker'
import Maintenance from '../components/Maintenance'
import awsExports from '../aws-exports'

const maintKey = '8889222755093'
const FORCE_MARKETPLACE_ID = process.env.REACT_APP_FORCE_MARKETPLACE_ID

export const AppConfigContext = createContext()

const AppConfigProvider = (props) => {
  const { children } = props
  const [configState, setConfigState] = useState({
    isLoading: true,
    marketplace: {},
  })
  const [ignoreMaint, setIgnoreMaint] = useState(false)
  const [fatalError, setFatalError] = useState(null)
  const [notFound, setNotFound] = useState(null)

  const { isLoading } = configState

  const query = useMemo(() => {
    const res = qs.parse(window.location.search.replace('?', ''))
    return res
  }, [])

  useEffect(() => {
    const localStorageMaintKey = localStorage.getItem('maintKey')
    if (query['maint-key'] === maintKey) {
      localStorage.setItem('maintKey', maintKey)
      setIgnoreMaint(true)
    } else if (localStorageMaintKey === maintKey) {
      setIgnoreMaint(true)
    }
  }, [query])

  const { marketplaceId } = useMemo(() => {
    const [, , pathPrimary, pathSecondary] = window.location.hostname.toLowerCase().split('.').reverse()
    // Remove once tested in production
    // const environment = pathPrimary === 'alpha' || pathPrimary === 'beta' ? pathPrimary : 'prod'
    // let marketplaceId = environment === 'prod' ? pathPrimary || 'dmlmarketplace' : pathSecondary || 'dmlmarketplace'

    // Default to the dml marketplace
    let marketplaceId = 'dmlmarketplace'
    // Known list of whitelabes
    const whitelabels = ['brandedcontentproject']
    // this is a test build of a whitelabel - use the path primary as the marketplace
    if (pathSecondary) marketplaceId = pathPrimary
    // this is either a whitelabel or a test build of dmlmarketplace
    else if (pathPrimary && whitelabels.includes(pathPrimary)) marketplaceId = pathPrimary

    if (FORCE_MARKETPLACE_ID) marketplaceId = FORCE_MARKETPLACE_ID
    return { marketplaceId }
  }, [])

  const isDmlLikeMarketplace = useMemo(() => marketplaceId.includes('dml'), [marketplaceId])

  // TODO: Move this into an api operator/shared comp
  const appStatusState = useQuery('appStatus', async () => {
    const reqRes = await axios.get('https://status.distributedmedialab.com/status.json')
    const serviceId = 'marketplace'
    const data = reqRes?.data || []
    const serviceStatusRecord = find(data, { service: serviceId })
    const response = {
      status: 'ok',
    }
    if (serviceStatusRecord?.status === 'maintenance') {
      const msg =
        (response.message = serviceStatusRecord?.notifications?.maintenance) ||
        'The marketplace is currently undergoing maintenance and will be back up and running soon.'
      response.status = 'maintenance'
      response.message = msg
    } else if (serviceStatusRecord?.status === 'warning') {
      const msg = (response.message = serviceStatusRecord?.notifications?.warning)
      if (msg) {
        response.status = 'warning'
        response.message = msg
      }
    }
    return response
  })

  const appStatusIsLoading = appStatusState.isLoading
  const appStatusRes = appStatusState.data

  useEffect(() => {
    if (marketplaceId) {
      const fetchData = async () => {
        try {
          const baseURL = get(awsExports, 'API.endpoints[0].endpoint')
          const marketplaceRes = await getMarketplaceMeta(marketplaceId, {
            baseURL,
          })
          setConfigState({
            marketplace: marketplaceRes.data,
            isLoading: false,
            productConfig: defaultsDeep(
              get(marketplaceRes, 'data.config.productTypeConfigOverride'),
              defaultProductTypeConfig,
            ),
          })
        } catch (e) {
          console.error(e)
          if (e.response && e.response.status === 404) {
            setNotFound(e)
          } else {
            setFatalError(e)
          }
          setConfigState({
            marketplace: null,
            isLoading: false,
          })
        }
      }
      fetchData()
    }
  }, [marketplaceId])

  if (!appStatusIsLoading && !isLoading && !!notFound) {
    return <p>Oops, the page you are looking for was not found.</p>
  }

  if (!appStatusIsLoading && !isLoading && !!fatalError) {
    return <p>Oops, there was an error loading the site.</p>
  }

  if (appStatusIsLoading || isLoading) return <FullscreenBlinker />

  if (!ignoreMaint && appStatusRes?.status === 'maintenance') {
    return <Maintenance msg={appStatusRes.message} />
  }

  const contextValue = {
    ...configState,
    marketplaceId,
    isDmlLikeMarketplace,
    appStatus: appStatusRes,
  }

  return <AppConfigContext.Provider value={contextValue}>{children}</AppConfigContext.Provider>
}

export default AppConfigProvider
