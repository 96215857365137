import { FC, useContext, useMemo, useCallback } from 'react'
import { lowerCase } from 'lodash'
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'

import { EmailAnchor, DialogTitle } from './elements'
import { UpgradeFlow } from './components'
import { UpgradePlanContext } from 'providers/UpgradePlanProvider'
import { AccountContext } from 'providers/AccountProvider'

export const UpgradePlanDialog: FC = () => {
  const {
    data: account,
    accountSubscription: { planName, billingInterval },
  } = useContext(AccountContext)
  const { toggleUpgradePlanDialog, isOpen, selectedPlan } = useContext(UpgradePlanContext)

  const handleDialogClose = useCallback(
    (_, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason !== 'backdropClick') {
        toggleUpgradePlanDialog()
      }
    },
    [toggleUpgradePlanDialog],
  )

  const parentAccount = useMemo(() => account?.parentAccountName || account?.parentAccountId, [account])

  const dialogText = useMemo(() => {
    if (parentAccount) {
      return `The feature you’ve selected requires a higher plan level. Your plan is currently managed by the account "${parentAccount}". Please contact the account owner to make plan changes.`
    } else if (lowerCase(planName).includes('network')) {
      return (
        <>
          The feature you’ve selected requires higher limits on your Network plan. Please email{' '}
          <EmailAnchor href="mailto:support@distributedmedialab.com">support@distributedmedialab.com</EmailAnchor> to
          increase your access.
        </>
      )
    } else if (lowerCase(planName).includes('plus')) {
      return (
        <>
          The feature you’ve selected requires a Network plan. Please email{' '}
          <EmailAnchor href="mailto:support@distributedmedialab.com">support@distributedmedialab.com</EmailAnchor> to
          discuss what level of feature access is right for you.
        </>
      )
    } else if (billingInterval && billingInterval !== 'month') {
      return (
        <>
          The feature you’ve selected requires a higher plan level. Please contact support at{' '}
          <EmailAnchor href="mailto:support@distributedmedialab.com">support@distributedmedialab.com</EmailAnchor> to upgrade your annual plan.
        </>
      )
    }
  }, [parentAccount, planName, billingInterval])

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      disableEnforceFocus
      disableEscapeKeyDown
      maxWidth={dialogText ? 'sm' : 'lg'}
      PaperProps={{
        sx: {
          minHeight: '20%',
          padding: '16px 32px 0 32px',
        },
      }}
    >
      {dialogText && !selectedPlan ? (
        <>
          <DialogTitle>Upgrade Your Plan</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleUpgradePlanDialog()} color="primary">
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <UpgradeFlow />
      )}
    </Dialog>
  )
}
