import styled from 'styled-components'
import { CardElement as StripeCardElement } from '@stripe/react-stripe-js'
import { Divider as MuiDivider, CircularProgress as MuiCircularProgress, Typography } from '@mui/material'

export const Divider = styled(MuiDivider)`
  margin: 16px 0 !important;
`

export const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`

interface ActionProps {
  justifyContent?: 'flex-start' | 'center' | 'flex-end'
}

export const ActionContainer = styled.div<ActionProps>`
  display: flex;
  justify-content: ${({ justifyContent = 'flex-end' }) => justifyContent};
  gap: 8px;
  margin-top: auto;
  margin-bottom: 16px;
`

export const CardInfoContainer = styled.div`
  display: flex;
`

export const CardElement = styled(StripeCardElement)`
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 8px 0;
  padding: 16px 0 16px 8px;
`

export const DefaultCardTypography = styled(Typography)`
  display: flex;
  align-items: center;
`

export const CardImage = styled.img`
  height: 20px;
  margin-left: 8px;
`

export const CircularProgress = styled(MuiCircularProgress)`
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  margin: auto 0;
`

export const PlanPaymentDescription = styled.div`
  margin: 8px 0;
`
