import amex from './amex.svg'
import diners from './diners.svg'
import discover from './discover.svg'
import genericCard from './generic.svg'
import jcb from './jcb.svg'
import mastercard from './mastercard.svg'
import unionPay from './unionpay.svg'
import visa from './visa.svg'

export const cards = {
  amex,
  diners,
  discover,
  genericCard,
  jcb,
  mastercard,
  unionPay,
  visa,
}
