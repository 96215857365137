/**
 * @file Top Level React App Component
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { useContext } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Authenticator } from 'aws-amplify-react'
import config from './aws-exports'
import { AppConfigContext } from './providers/AppConfigProvider'
import AuthWrapper from './AuthWrapper'

import './App.css'

function App() {
  const {
    marketplace: {
      description: marketplaceDescription,
      config: { icon: marketplaceIconPath },
    },
  } = useContext(AppConfigContext)
  let initialState = 'signIn'

  const showTerms = window.location.pathname === '/terms-of-service'
  const showPrivacyPolicy = window.location.pathname === '/privacy-policy'

  // Check for an invite id - we are outside of a router so not available as a prop
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const inviteToken = urlParams.get('invite')
  const requestAccess = urlParams.get('request-access')
  if (inviteToken) {
    initialState = 'signUp'
  } else if (requestAccess) {
    initialState = 'marketplaceRequestAccess'
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{marketplaceDescription}</title>
        <meta name="description" content={marketplaceDescription} />
        <link rel="icon" href={marketplaceIconPath} />
      </Helmet>
      <div>
        <Authenticator authState={initialState} hideDefault amplifyConfig={config}>
          <AuthWrapper
            inviteToken={inviteToken}
            showTerms={showTerms}
            showPrivacyPolicy={showPrivacyPolicy}
            marketplaceRequestAccess
          />
        </Authenticator>
      </div>
    </HelmetProvider>
  )
}

export default App
