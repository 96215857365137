import React, { FC } from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import whiteFullDmlLogo from '../assets/dml/dml-color.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  logo: {
    maxWidth: '500px',
    width: '90%',
  },
  msg: {
    maxWidth: '500px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  contact: {},
}))

const Maintenance: FC<{ msg: string }> = ({ msg }) => {
  const classes = useStyles()
  return (
    <div className={cx(classes.container, 'animate__animated animate__shakeX')}>
      <img className={classes.logo} src={whiteFullDmlLogo} />
      <Alert severity="warning" className={classes.msg}>
        {msg}
      </Alert>
      <p className={classes.contact}>
        Please email <a href="mailto:support@distributedmedialab.com">support@distributedmedialab.com</a> for any urgent
        issues.
      </p>
    </div>
  )
}

export default Maintenance
