import styled from 'styled-components'
import { Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export const Link = styled(RouterLink)<{ $disabled?: boolean }>`
  text-decoration: none;
  ${({ $disabled }) => ($disabled ? 'pointer-events: none;' : '')}
`

export const ConfirmContainer = styled.div`
  width: 50%;
  height: 50%;
  padding-left: 8px;
`

export const Header = styled(Typography)`
  margin: 16px 0 !important;
`

export const Spacer = styled.div<{ pixels?: number }>`
  padding: ${({ pixels = 24 }) => pixels}px;
`
