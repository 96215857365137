/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

import React, { useContext } from 'react'
import AuthHead from '@dmlab/ui-components-amplify'
import { AppConfigContext } from './providers/AppConfigProvider'
import { withOrigin } from './utils'
import AppWithAuth from './AppWithAuth'
import PrivacyPolicy from './views/PrivacyPolicy'
import TermsOfService from './views/TermsOfService'

function AuthWrapper(props) {
  const {
    authData,
    authState,
    inviteToken,
    onStateChange: handleStateChange,
    showTerms,
    showPrivacyPolicy,
    marketplaceRequestAccess,
  } = props

  const {
    marketplace: {
      name: marketplaceName,
      config: {
        registrationLogo,
        registrationDescription,
        registrationEmailContact,
        linkedin,
        facebook,
        twitter,
        youtube,
        instagram,
        logo: marketplaceLogoPath,
      },
    },
    marketplaceId,
  } = useContext(AppConfigContext)

  if (showTerms) {
    return <TermsOfService marketplace={marketplaceName} logoPath={marketplaceLogoPath} />
  }

  if (showPrivacyPolicy) {
    return <PrivacyPolicy marketplace={marketplaceName} logoPath={marketplaceLogoPath} />
  }

  if (!['loading', 'signedIn'].includes(authState)) {
    return (
      <AuthHead
        authState={authState}
        authData={authData}
        marketplaceRequestAccess={marketplaceRequestAccess}
        onStateChange={handleStateChange}
        name={marketplaceName}
        description={registrationDescription}
        registrationLogoPath={withOrigin(registrationLogo)}
        inviteToken={inviteToken}
        emailContact={registrationEmailContact}
        social={{
          linkedin,
          facebook,
          twitter,
          youtube,
          instagram,
        }}
        isRegister
        marketplaceId={marketplaceId}
      />
    )
  }

  if (authState === 'signedIn') {
    return <AppWithAuth onStateChange={handleStateChange} />
  }

  return null
}

export default AuthWrapper
