import { FC, useCallback, useContext, useMemo } from 'react'
import { DialogActions, Button, Typography, DialogContent } from '@mui/material'

import { Grid, BoldText, DialogTitle } from '../../elements'
import { PlanOption } from './PlanOption'
import planOptions from 'utils/plans.json'
import { EmailAnchor } from 'features/upgrade-plan-dialog/elements'
import { AccountContext } from 'providers/AccountProvider'
import { PlanType } from 'features/manage-plan/interfaces'
import { UpgradePlanContext } from 'providers/UpgradePlanProvider'

export const PlansOverview: FC = () => {
  const {
    accountSubscription: { planName },
  } = useContext(AccountContext)
  const { toggleUpgradePlanDialog, setSelectedPlan, plansToInclude } = useContext(UpgradePlanContext)

  const handleSelectedPlan = useCallback((name: PlanType) => () => setSelectedPlan(name), [])

  const specificPlanOptions = useMemo(() => {
    return planOptions.slice(-plansToInclude)
  }, [plansToInclude])

  const currentPlanIndex = useMemo(
    () => specificPlanOptions.findIndex((plan) => planName.includes(plan.name)),
    [planName],
  )

  return (
    <>
      <DialogTitle>Upgrade Your Plan</DialogTitle>

      <DialogContent>
        <Typography>
          The feature you’ve selected requires a higher plan level. If you’d like to continue please select the
          highlighted plan below, or higher.
        </Typography>
        <BoldText display="inline-block">Need even more access, or have questions?</BoldText>{' '}
        <Typography display="inline">
          <EmailAnchor href="mailto:support@distributedmedialab.com">Contact DML support</EmailAnchor>.
        </Typography>
        <Grid container spacing={2}>
          {specificPlanOptions.map((plan, ind) => (
            <PlanOption
              key={`${plan.name}-plan`}
              {...plan}
              isCurrent={currentPlanIndex === ind}
              isNextPlan={currentPlanIndex + 1 === ind}
              handleSelectedPlan={handleSelectedPlan(plan.name as PlanType)}
            />
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => toggleUpgradePlanDialog()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}
