/**
 * @file Component to render request access flow
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { useState, useMemo } from 'react'
import cx from 'classnames'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/styles'
import { requestAccess } from '@dmlab/ui-api-access'
import { Auth } from '@aws-amplify/auth'
import { withOrigin } from '../../utils'
import { get } from '../../utils/lodash'
import { createLogger } from '../../setupLogs'

const log = createLogger('NoAccess')

const useStyles = makeStyles((theme) => ({
  section: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    zIndex: '999999999',
    backgroundColor: '#fff',
    maxWidth: 'unset',
  },
  inner: {
    maxWidth: '500px',
    textAlign: 'center',
    width: '100%',
    paddingBottom: theme.spacing(4),
  },
  logo: {
    marginBottom: theme.spacing(3),
    width: '80%',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  contact: {
    paddingTop: theme.spacing(2),
    '& > a': {
      color: theme.palette.info.main,
    },
  },
  signOut: {
    marginLeft: theme.spacing(1),
  },
  marketplaceLink: {
    textDecoration: 'none',
  },
  alignLeft: {
    textAlign: 'left',
  },
  accessDML: {
    marginBottom: theme.spacing(2),
  },
}))

const NoAccess = (props) => {
  const { marketplace, accessRequestPending, userRequestedNotification } = props

  const classes = useStyles(props)
  const registrationLogoPath = withOrigin(get(marketplace, 'config.registrationLogo', ''))
  const [reqState, setReqState] = useState({ isLoading: false, reqError: null })
  const [accessRequested, setAccessRequested] = useState(accessRequestPending)
  const [willBeNotified, setWillBeNotified] = useState(userRequestedNotification)

  const isTBCPStorefront = useMemo(() => marketplace.id === 'brandedcontentproject', [marketplace])

  const { isLoading, reqError } = reqState

  const handleRequestAccess = async () => {
    try {
      setReqState({ isLoading: true })
      await requestAccess(`marketplace:${marketplace.id}`)
      setReqState({ isLoading: false })
      setAccessRequested(true)
      setWillBeNotified(true)
    } catch (e) {
      const errMsg = get(e, 'response.data.errors[0].details')
      setReqState({ reqError: errMsg })
      log.error('Request Access Error', e)
    }
  }

  const handleRequestNotification = async () => {
    try {
      setReqState({ isLoading: true })
      await requestAccess(`marketplace:${marketplace.id}`, {
        data: { notify: true },
      })
      setReqState({ isLoading: false })
      setWillBeNotified(true)
    } catch (e) {
      const errMsg = get(e, 'response.data.errors[0].details')
      setReqState({ reqError: errMsg })
      log.error('Request Notification Error', e)
    }
  }

  const handleSignOut = async () => {
    try {
      await Auth.signOut()
    } catch (e) {
      log.error('error logging user out', e)
    }
  }

  return (
    <section className={cx(classes.section, 'animate__animated animate__headShake')}>
      <div className={classes.inner}>
        <img src={registrationLogoPath} alt={marketplace.name} className={classes.logo} />
        {!!reqError && (
          <Alert severity="error" className={cx(classes.alert, 'animate__animated animate__headShake')}>
            {reqError}
          </Alert>
        )}
        {isTBCPStorefront ? (
          <Alert severity="info" className={classes.alert}>
            Hi! The Branded Content Project storefront has moved to the <b>Distributed Media Lab</b> marketplace to
            provide you with expanded resources and revenue opportunities. Please visit us at our new home to:
            <ul className={classes.alignLeft}>
              <li>access all of the Branded Content Project products</li>
              <li>download sales and marketing resources</li>
              <li>order/update a Branded Content Project campaign</li>
            </ul>
            The Distributed Media Lab marketplace is accessible with the same username and password that you currently
            use for the storefront.
          </Alert>
        ) : (
          <Alert severity="info" className={classes.alert}>
            {!accessRequested &&
              `Your account does not have access to the marketplace "${marketplace.name}". You can request access below.`}
            {accessRequested &&
              willBeNotified &&
              `There is a pending access request for your account to access the marketplace "${marketplace.name}". You will be notified once access is available.`}
            {accessRequested &&
              !willBeNotified &&
              `There is a pending access request for your account to access the marketplace "${marketplace.name}". Below you can request to be notified once access is available.`}
          </Alert>
        )}
        {isTBCPStorefront && (
          <a
            href="https://dml.market/thebrandedcontentproject"
            target="_blank"
            rel="noreferrer"
            className={classes.marketplaceLink}
          >
            <Button variant="contained" color="primary" className={classes.accessDML}>
              Access Marketplace
            </Button>
          </a>
        )}
        {!isTBCPStorefront && !accessRequested && (
          <Button
            onClick={handleRequestAccess}
            variant="contained"
            color="primary"
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={10} color="inherit" /> : null}
          >
            Request Access
          </Button>
        )}
        {!isTBCPStorefront && !willBeNotified && accessRequested && (
          <Button
            onClick={handleRequestNotification}
            variant="contained"
            color="primary"
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={10} color="inherit" /> : null}
          >
            Request Access Notification
          </Button>
        )}
        {!isTBCPStorefront && (
          <Button variant="outlined" disabled={isLoading} onClick={handleSignOut} className={classes.signOut}>
            Sign Out
          </Button>
        )}
        {isTBCPStorefront && (
          <Alert severity="info" className={classes.alert}>
            Are you trying to download articles?
            <div>
              We're streamlining the process and will be e-mailing monthly articles directly to partners on the last
              business day of each month. Please contact us at{' '}
              <a href="support@distributedmedialab.com">support@distributedmedialab.com</a> and let us know which series
              you're interested in downloading and we'll add you to the appropriate distribution list.
            </div>
          </Alert>
        )}
        <p>
          <Typography>If you have any questions, please let us know!</Typography>
          Julia Campbell: <a href="mailto:julia@brandedcontentproject.com">julia@brandedcontentproject.com</a>
          <br />
          DML Support: <a href="mailto:support@distributedmedialab.com">support@distributedmedialab.com</a>
        </p>
      </div>
    </section>
  )
}

export default NoAccess
