import { useContext, Dispatch, SetStateAction, useEffect, useMemo, useCallback } from 'react'

import { AccountContext } from 'providers/AccountProvider'
import { Card, PaymentState } from '../../../interfaces'

interface Data {
  paymentState: PaymentState
  setPaymentState: Dispatch<SetStateAction<PaymentState>>
}

export const usePaymentForm = ({ setPaymentState, paymentState }: Data) => {
  const { paymentMethodsAreEmpty, data = {} } = useContext(AccountContext)

  const paymentMethods = data?.paymentMethods

  const cardInfo = useMemo((): Card | null => (!paymentMethodsAreEmpty ? paymentMethods?.[0] : null), [paymentMethods])

  useEffect(() => {
    if (!cardInfo) {
      setPaymentState({ option: 'newCard', completed: false })
    }
  }, [cardInfo])

  const showCardElement = useMemo(() => paymentState.option === 'newCard', [paymentState])

  useEffect(() => {
    setPaymentState((curr) => ({ ...curr, completed: !!cardInfo }))
  }, [cardInfo])

  const handleRadioChange = useCallback(
    ({ target: { value } }) => {
      setPaymentState({
        option: value,
        completed: value === 'default',
      })
    },
    [setPaymentState],
  )

  const handleCardChange = useCallback(
    ({ complete }) => {
      setPaymentState((curr) => ({ ...curr, completed: complete }))
    },
    [setPaymentState],
  )

  return {
    cardInfo,
    showCardElement,
    paymentMethodsAreEmpty,
    handleRadioChange,
    handleCardChange,
  }
}
