import { Dispatch, FC, SetStateAction, useCallback, useContext } from 'react'
import { Button, Grid, Typography } from '@mui/material'

import planOptions from 'utils/plans.json'
import { Option } from './Option'
import { Link, Spacer } from '../sharedElements'
import { LimitAlert, CardContainer, NetworkContent } from './elements'
import { AccountContext } from 'providers/AccountProvider'
import { PlanType } from 'features/manage-plan/interfaces'

interface Props {
  reachedLimits: boolean
  setSelectedPlan: Dispatch<SetStateAction<PlanType>>
}

export const PlanOptions: FC<Props> = ({ reachedLimits, setSelectedPlan }) => {
  const {
    accountSubscription: { planName: currentPlan, dmlAccountPlan, amountBilledPerMonth, isLoading },
  } = useContext(AccountContext)

  const handleMailClick = () => {
    window.location.href = 'mailto:support@distributedmedialab.com'
  }

  const handlePlanSelected = useCallback((plan: PlanType) => () => setSelectedPlan(plan), [setSelectedPlan])

  return (
    <>
      {reachedLimits && (
        <LimitAlert severity="error">
          Your current ad campaigns or series license orders exceed the limit for the plan you have selected. Please
          modify the end dates of your existing orders to within this billing period or cancel them to downgrade your
          plan. Contact us at support@distributedmedialab.com if you have questions.
        </LimitAlert>
      )}

      <Grid container spacing={2}>
        {planOptions.map((plan) => (
          <Option
            key={`${plan.name}-plan`}
            {...plan}
            isCurrent={currentPlan?.includes(plan.name)}
            isLoading={isLoading}
            hasCustomPrice={amountBilledPerMonth !== dmlAccountPlan.price}
            customerPrice={amountBilledPerMonth}
            handlePlanSelected={handlePlanSelected(plan.name as PlanType)}
          />
        ))}
      </Grid>

      <Spacer />

      <CardContainer>
        <NetworkContent>
          <Typography component="h2" variant="h1" fontWeight={500}>
            Looking for more access and features?
          </Typography>
          <Typography>
            Our Network Plan includes extra features to help support managing multiple outlets or companies, as well as
            additional access to ad campaigns and content. Contact us at support@distributedmedialab.com to discuss
            Network pricing.
          </Typography>
          <Link to="#" onClick={handleMailClick}>
            <Button variant="contained">Contact</Button>
          </Link>
        </NetworkContent>
      </CardContainer>
    </>
  )
}
