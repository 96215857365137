/**
 * @copyright Copyright 2022-present, Distributed Media Lab, Inc.
 */

import { Suspense, lazy, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AppLayout } from '@dmlab/ui-app-layout'
import { faqsShowPath } from '@dmlab/ui-faqs-show'
import { Alert } from '@material-ui/lab'

import { get } from './utils/lodash'
import { drawerWidth } from './components/Footer'
import { useMenuItems } from './hooks/useMenuItems'
import { AppConfigContext } from './providers/AppConfigProvider'
import { AccountContext } from './providers/AccountProvider'
import { UpgradePlanDialog } from 'features/upgrade-plan-dialog'

const Account = lazy(() => import('./views/Account'))
const Product = lazy(() => import('./views/Product'))
const Products = lazy(() => import('./views/Products'))
const Faq = lazy(() => import('./views/Faq'))
const Storefront = lazy(() => import('./views/Storefront'))
const Sections = lazy(() => import('./views/Sections'))
const Section = lazy(() => import('./views/Section'))
const NotFound = lazy(() => import('./views/NotFound'))
const ImplementationGuide = lazy(() => import('./views/resources/ImplementationGuide'))
const Layouts = lazy(() => import('./views/resources/Layouts'))
const Subscriptions = lazy(() => import('./views/Subscriptions'))
const TermsOfUse = lazy(() => import('./views/TermsOfUse'))
const TermsAndConditions = lazy(() => import('./views/TermsAndConditions'))
const CampaignManager = lazy(() => import('./views/CampaignManager'))
const Users = lazy(() => import('./views/Users'))
const VideoBoltTerms = lazy(() => import('./views/VideoBoltTerms'))
const Profile = lazy(() => import('./views/Profile'))
const CustomContentRequest = lazy(() => import('./views/CustomContentRequest'))
const CustomContentOrder = lazy(() => import('./views/custom-content-order'))
const ManagePlanView = lazy(() => import('./views/ManagePlan'))

const Routes = ({ handleStateChange }) => {
  const account = useContext(AccountContext)
  const {
    marketplace: {
      config: { marketplacePrimaryColor = '#000' },
    },
    appStatus,
  } = useContext(AppConfigContext)

  const { isWarning, warningMsg } = useMemo(() => {
    const isWarning = appStatus?.status === 'warning'
    const warningMsg = appStatus?.message
    return { isWarning, warningMsg }
  }, [])

  const userIdentity = useSelector((state) => state.userIdentity)
  const ownerId = useMemo(() => get(account, 'data.ownerId', null), [account])
  const userId = useMemo(() => get(userIdentity, 'userId'), [userIdentity])
  const isOwner = useMemo(() => ownerId === userId, [ownerId, userId])

  const menuItems = useMenuItems(isOwner)

  return (
    <Switch>
      <Redirect exact from="/" to="/storefront" />
      <AppLayout
        drawerWidth={drawerWidth}
        menuItems={menuItems}
        onStateChange={handleStateChange}
        appBarColor={marketplacePrimaryColor}
      >
        {isWarning && <Alert severity="warning">{warningMsg}</Alert>}
        <Suspense fallback={<span />}>
          <Switch>
            <Route exact path="/storefront" component={Storefront} />
            <Route exact path="/not-found" component={NotFound} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/account/manage-plan" component={ManagePlanView} />
            <Route exact path="/products/:productId" component={Product} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/resources/implementation-guide" component={ImplementationGuide} />
            <Route exact path="/resources/layouts" component={Layouts} />
            <Route path={faqsShowPath} component={Faq} />
            <Route exact path="/subscriptions" component={Subscriptions} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
            <Route exact path="/video-bolt-terms" component={VideoBoltTerms} />
            <Route exact path="/users" component={Users} />
            <Route path="/campaigns" component={CampaignManager} />
            <Route exact path="/profile" component={Profile} />
            <Route path="/custom-content-request" component={CustomContentRequest} />
            <Route path="/custom-content-order" component={CustomContentOrder} />
            <Route exact path="/sections" component={Sections} />
            <Route exact path="/:sectionId" component={Section} />
            <Route exact path="/:sectionId/:productId" component={Product} />
          </Switch>
        </Suspense>
        <UpgradePlanDialog />
      </AppLayout>
    </Switch>
  )
}

export default Routes
