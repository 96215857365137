/**
 * @file Hook to retrieve menuItems for navBar
 * @copyright Copyright 2022-present, Distributed Media Lab, Inc.
 */

import StorefrontIcon from '@material-ui/icons/Storefront'
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import PeopleIcon from '@material-ui/icons/People'
import AppsIcon from '@material-ui/icons/Apps'
import ListIcon from '@material-ui/icons/List'
import BallotIcon from '@material-ui/icons/Ballot'
import CustomContentIcon from '@material-ui/icons/Description'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import CreateCollectionIcon from '@material-ui/icons/Palette'

export const useMenuItems = (isOwner: boolean) => [
  {
    label: 'Storefront',
    Icon: StorefrontIcon,
    to: '/storefront',
  },
  {
    label: 'All Sections',
    Icon: ViewCompactIcon,
    to: '/sections',
  },
  {
    label: 'All Products',
    Icon: AppsIcon,
    to: '/products',
  },
  {
    label: 'My Products',
    Icon: ListIcon,
    to: '/subscriptions',
  },
  {
    label: 'Ad Campaigns',
    Icon: BallotIcon,
    to: '/campaigns',
  },
  {
    label: 'Custom Content',
    Icon: CustomContentIcon,
    to: '/custom-content-request',
  },
  {
    label: 'Create',
    Icon: CreateCollectionIcon,
    link: 'https://hub.distributedmedialab.com/dashboard?referred-from=marketplace',
  },
  {
    label: 'Account',
    Icon: FolderSharedIcon,
    to: '/account',
  },
  {
    label: 'Profile',
    Icon: AccountCircleIcon,
    to: '/profile',
  },
  ...(isOwner
    ? [
        {
          label: 'Users',
          Icon: PeopleIcon,
          to: '/users',
        },
      ]
    : []),
  {
    label: 'FAQ',
    Icon: LiveHelpIcon,
    to: '/faq',
  },
]
