/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */
const FORCE_ASSET_URL_BASE_PATH = process.env.REACT_APP_FORCE_ASSET_URL_BASE_PATH

const urlJoin = require('url-join')
const baseUrl = FORCE_ASSET_URL_BASE_PATH || window.location.origin

export const fullAssetUrl = (path) => {
  // Return fully qualified urls
  if (path.startsWith('http')) return path
  return urlJoin(baseUrl, 'assets', path)
}

export const withOrigin = (path) => {
  // Return fully qualified urls
  if (path.startsWith('http')) return path
  return urlJoin(baseUrl, path)
}

export const sleep = (m) => new Promise((resolve) => setTimeout(resolve, m))

export const urlTestRegex = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&=]*)/
