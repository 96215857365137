import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DmlBlinker from './DmlBlinker'

const useStyles = makeStyles(() => ({
  container: {
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    zIndex: '99999',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blinkContainer: {
    height: '100px',
    width: '100px',
  },
}))

const FullscreenBlinker = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.blinkContainer}>
        <DmlBlinker />
      </div>
    </div>
  )
}

export default FullscreenBlinker
