/**
 * @file Provider to share subscriptions data around the SPA
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { createContext, useState, useEffect, useContext } from 'react'
import { createLogger } from '../setupLogs'
import { CancelToken, isCancel } from 'axios'
import { get, isFunction, keyBy } from '../utils/lodash'
import { getSubscriptions } from '@dmlab/ui-api-subscriptions'
import { AppConfigContext } from './AppConfigProvider'

const log = createLogger('SubscriptionsProvider')

export const SubscriptionsContext = createContext()

const SubscriptionsProvider = (props) => {
  const { children } = props
  const appConfig = useContext(AppConfigContext)
  const [lastRefresh, setLastRefresh] = useState(new Date().toISOString())
  const [reqState, setReqState] = useState({ isLoading: false, error: null, source: null })
  const [subscriptions, setSubscriptions] = useState([])

  const {
    marketplace: { id: marketplaceId },
  } = appConfig

  const { source } = reqState

  const refreshSubscriptions = () => {
    log.info('refreshSubscriptions - triggered')
    if (isFunction(get(source, 'cancel'))) source.cancel()
    setLastRefresh(new Date().toISOString())
  }

  useEffect(() => {
    const source = CancelToken.source()

    const fetchSubscriptions = async () => {
      log.debug('useEffect[]fetchSubscriptions - triggered')
      try {
        setReqState({ isLoading: true, source })
        const subRes = await getSubscriptions({
          params: {
            // TODO: How should we handle limits differently
            limit: 200,
            include: 'products',
            subscribed: true,
            marketplaceId,
          },
        })
        const keyedProductsById = keyBy(get(subRes, 'data.products', []), 'id')
        const subscriptionsWithProducts = get(subRes, 'data.items', []).map((subscription) => ({
          ...subscription,
          product: keyedProductsById[subscription.productId],
        }))
        log.debug('useEffect[]fetchSubscriptions -> const[]subscriptionsWithProducts', subscriptionsWithProducts)
        setSubscriptions(subscriptionsWithProducts)
        setReqState({ isLoading: false })
      } catch (e) {
        if (isCancel(e)) {
          setReqState({})
        } else {
          setReqState({ error: e })
        }
      }
    }

    fetchSubscriptions()

    return () => {
      if (isFunction(get(source, 'cancel'))) source.cancel()
    }
  }, [lastRefresh, marketplaceId])

  const value = {
    ...reqState,
    lastRefresh,
    subscriptions,
    refreshSubscriptions,
  }

  return <SubscriptionsContext.Provider value={value}>{children}</SubscriptionsContext.Provider>
}

export default SubscriptionsProvider
