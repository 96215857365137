/**
 * Copyright 2021-present, Distributed Media Lab, Inc.
 */

import { useContext } from 'react'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { AppLayoutContext } from '@dmlab/ui-app-layout'
import { PrimaryWhiteLogoDml } from '@dmlab/ui-dml-assets'

import { AppConfigContext } from '../providers/AppConfigProvider'

export const footerHeight = 35
export const drawerWidth = 215

const useStyles = makeStyles((theme) => ({
  footer: {
    height: `${footerHeight}px`,
    color: '#fff',
    backgroundColor: theme.palette.marketplacePrimary,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing(9) + 1}px)`,
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  footerContentContainerShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  poweredBy: {
    lineHeight: `${theme.spacing(2.25)}px`,
    paddingLeft: theme.spacing(3),
    alignItems: 'center',
  },
  termsAndPrivacyContainer: {
    paddingRight: theme.spacing(3),
  },
  termsOfService: {
    color: '#fff',
  },
  privacyPolicy: {
    color: '#fff',
  },
  copyright: {
    color: '#fff',
    pointerEvents: 'none',
  },
  pipe: {
    color: '#fff',
    padding: `0px ${theme.spacing(1)}px`,
  },
  logoLink: {
    height: theme.spacing(1.875), // 15px
    paddingLeft: theme.spacing(0.5),
  },
  logo: {
    height: theme.spacing(2),
  },
}))

const Footer = (props) => {
  const classes = useStyles(props)
  const appLayout = useContext(AppLayoutContext)
  const appContext = useContext(AppConfigContext)

  const {
    marketplace: { name: marketplaceName },
  } = appContext

  const { menuIsExpanded } = appLayout

  const dt = new Date()
  const fullYear = dt.getFullYear()

  return (
    <footer className={classes.footer}>
      <div
        className={cx(classes.footerContentContainer, {
          [classes.footerContentContainerShift]: menuIsExpanded,
        })}
      >
        <span className={classes.poweredBy}>
          Powered by&nbsp;
          <a className={classes.logoLink} href="https://www.distributedmedialab.com/" target="_blank" rel="noreferrer">
            <PrimaryWhiteLogoDml className={classes.logo} title="Distributed Media Lab" />
          </a>
        </span>

        <span className={classes.termsAndPrivacyContainer}>
          <a
            className={classes.termsOfService}
            href="https://dml.market/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
          <span className={classes.pipe}>|</span>
          <a
            className={classes.privacyPolicy}
            href="https://dml.market/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <span className={classes.pipe}>|</span>
          <a className={classes.copyright}>
            © {fullYear} {marketplaceName}
          </a>
        </span>
      </div>
    </footer>
  )
}

export default Footer
