import { FC } from 'react'
import format from 'date-fns/format'
import { Typography } from '@mui/material'

import { SuccessfulState } from '../../interfaces'
import { upgradeSuccessText, downgradeSuccessText } from './constants'
import { Header, Spacer } from '../sharedElements'
import { ActionContainer } from './elements'

export const PlanChangeSuccessful: FC<SuccessfulState> = ({ successType, effective, children }) => {
  return (
    <>
      <Header variant="h4">Plan successfully changed</Header>

      <Typography>
        Thank you for your order.{' '}
        {successType === 'upgrade'
          ? upgradeSuccessText
          : `${downgradeSuccessText} ${format(new Date(Number(effective)), 'MMMM dd, yyyy')}`}
      </Typography>

      <Spacer />

      <ActionContainer justifyContent="flex-start">{children}</ActionContainer>
    </>
  )
}
