/**
 * @file Terms of Service page
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React from 'react'
import { Helmet } from 'react-helmet-async'
// MUI Components
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
// Internal
import SectionHeader from '../components/SectionHeader'
import { useRegisterPageView } from 'hooks/useRegisterPageView'
import { withOrigin } from '../utils'
import terms from '../utils/termsOfService.json'

const boldText = {
  Account: (
    <span>
      In order to access and use the Services, Publisher will need to register with DML and create an account (“
      <b>Account</b>“).
    </span>
  ),
  'Company’s Data': (
    <span>
      DML does not claim any ownership rights in any data, information, documents or other materials that Publisher
      provides or prepares using the Services (collectively, “<b>Data</b>”). Nothing in this Agreement will be deemed to
      restrict any rights that Publisher may have to use and exploit the Data. DML may store and use certain Data and
      Metadata to identify common patterns and improve DML’s products and services. “<b>Metadata</b>” means metadata
      associated with Publisher’s use of the Services. For clarity, Metadata does not include Publisher’s Data. DML may
      store and use certain data associated with Authorized Users, including IP addresses, stored session identifiers,
      and account credentials (collectively, “<b>User Data</b>”).
    </span>
  ),
  Feedback: (
    <span>
      If Publisher provides DML with any suggestions for improvement, comments, or other feedback regarding the Services
      (“<b>Feedback</b>”),
    </span>
  ),
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.marketplacePrimary,
    height: theme.spacing(7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: theme.spacing(4),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  padded: {
    paddingLeft: theme.spacing(2),
  },
  header: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
}))

const TermsOfService = (props) => {
  const { marketplace, logoPath } = props
  const classes = useStyles(props)

  useRegisterPageView('/terms-of-service', 'Terms of Service')

  return (
    <>
      <Helmet>
        <title>Terms of Service | {marketplace}</title>
      </Helmet>

      <div className={classes.appBar}>
        <img src={withOrigin(logoPath)} className={classes.logo} alt={logoPath} />
      </div>

      <section>
        <div className={classes.title}>
          <SectionHeader label="PLATFORM SERVICES TERMS OF SERVICE" />
        </div>

        <div className={classes.padded}>
          <Typography className={classes.header}>Last Changes to Terms of Service:</Typography>
          <br />
          <Typography>
            The policies below are applicable to and governs your (“<b>you,</b>” “<b>your,</b>” or ”<b>Publisher</b>”)
            access to and use of the materials made available by Distributed Media Lab hereunder (”<b>We,</b>” ”
            <b>us,</b>” ”<b>our,</b>” or ”<b>DML</b>”) including DML’s platform (“<b>DML Platform</b>”), the DML console
            (“<b>DML Console</b>”) (where applicable), DML content made available to Publisher (“<b>DML Content</b>”),
            the DML website (the “<b>DML Site</b>”) and related services. The DML Platform, DML Console, DML Content,
            the DML Site and related services are collectively referred to as, the (“<b>Services</b>”).
          </Typography>
          <br />
          <Typography>
            <b>
              BY USING OUR SERVICES, YOU ARE ACCEPTING THE PRACTICES DESCRIBED IN THESE TERMS OF SERVICE. IF YOU DO NOT
              AGREE TO THESE TERMS OF SERVICE, PLEASE DO NOT USE THE SERVICES AND EXIT IMMEDIATELY. WE RESERVE THE RIGHT
              TO MODIFY OR AMEND THESE TERMS OF SERVICE FROM TIME TO TIME WITHOUT NOTICE. YOUR CONTINUED USE OF OUR
              SERVICES FOLLOWING THE POSTING OF CHANGES TO THESE TERMS WILL MEAN YOU ACCEPT THOSE CHANGES. UNLESS WE
              PROVIDE YOU WITH SPECIFIC NOTICE, NO CHANGES TO OUR TERMS OF SERVICE WILL APPLY RETROACTIVELY.
            </b>
            You may be able to access and view some materials for free and without registering for an account, but
            certain features may only be available if you (a) register for an account and sign in to the associated
            service; or (b) if you subscribe to the service and pay the associated subscription fee.
          </Typography>
          <br />
          <Typography>
            This is a legal agreement between Publisher and DML that states the material terms and conditions that
            govern your use of the Services. This agreement, together with all updates, supplements, additional terms,
            and all of DML’s rules and policies collectively constitute this "Agreement" between Publisher and DML. BY
            ACCESSING THE SERVICES, YOU AGREE TO BE LEGALLY BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF
            SERVICE STATED HEREIN, PLEASE DO NOT USE THE SERVICES.
          </Typography>
        </div>

        {terms.map(({ title, text, sections = [] }, i) => (
          <div key={title}>
            <Typography variant="h5" className={classes.header}>
              {i + 1}. {title}
            </Typography>
            {!!text && <Typography className={classes.padded}>{text}</Typography>}

            {sections.map(({ sectionTitle, subText, hasBold }, subIndex) => (
              <div key={`${title}-${sectionTitle}`}>
                <Typography className={classes.header}>
                  {`${i + 1}.${subIndex + 1} `}
                  {sectionTitle}
                </Typography>
                <Typography className={classes.padded}>
                  {hasBold ? (
                    <div>
                      {boldText[sectionTitle]} {subText}
                    </div>
                  ) : (
                    subText
                  )}
                </Typography>
              </div>
            ))}
          </div>
        ))}
      </section>
    </>
  )
}

export default TermsOfService
