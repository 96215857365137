import { useState, useMemo, useContext, useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { createAccountPaymentMethod, updateAccountPaymentMethod } from '@dmlab/ui-api-accounts'
import { useGetSubscriptionPreview, useUpdateAccountPlanSubscription } from '@dmlab/ui-api-account-plan-subscriptions'
import { UpdateAccountPlanArgs } from '@dmlab/ui-api-account-plan-subscriptions/dist/interfaces'

import { Props } from '../index'
import { invoiceDataKey } from 'hooks/data-fetch/cacheKeys'
import planOptions from 'utils/plans.json'
import { AccountContext } from 'providers/AccountProvider'
import { PaymentState, ConfirmationState, SuccessfulState } from 'features/manage-plan/interfaces'
import { QuotasContext } from 'providers/QuotasProvider'

export const usePayments = (props: Props) => {
  const { planName, handleReachedLimits, setSelectedPlan } = props
  const queryClient = useQueryClient()
  const {
    data,
    accountSubscription: { existingPlanId, amountBilledPerMonth },
    fetchAccount: refectAccount,
    refetchSubscription,
  } = useContext(AccountContext)
  const { invalidateCampaignsQuota, invalidateLicenseOrdersQuota, invalidateAmpArticlesQuota } =
    useContext(QuotasContext)
  const [saveCard, setSaveCard] = useState(false)
  const [planSuccessfulState, setPlanSuccessfulState] = useState<SuccessfulState | null>()
  const [confirmationState, setConfirmationState] = useState<ConfirmationState>({})
  const [paymentState, setPaymentState] = useState<PaymentState>({ option: 'default', completed: false })

  const Stripe = useStripe()
  const elements = useElements()

  const accountId = useMemo(() => data?.id, [data])

  const {
    subscriptionPreview,
    isLoading: previewLoading,
    isError: previewError,
  } = useGetSubscriptionPreview(
    accountId,
    {
      planName: planName as string,
      existingPlanId,
    },
    {
      cacheTime: 0,
    },
  )

  useEffect(() => {
    if (handleReachedLimits) {
      handleReachedLimits(subscriptionPreview?.reachedLimits || false)
    }
    if (subscriptionPreview?.reachedLimits) {
      setSelectedPlan(null)
    }
  }, [handleReachedLimits, subscriptionPreview?.reachedLimits, setSelectedPlan])

  const { mutateAsync: updateSubscription, isLoading, isError } = useUpdateAccountPlanSubscription(accountId)

  const { isPaidPlan, selectedPlanPrice } = useMemo(
    () => ({
      isPaidPlan: planName !== 'Free',
      selectedPlanPrice: planOptions.find((plan) => plan.name === planName)?.price,
    }),
    [planName],
  )

  const refetchAndInvalidateData = useCallback(() => {
    refectAccount()
    refetchSubscription()
    queryClient.invalidateQueries([invoiceDataKey])
    invalidateCampaignsQuota()
    invalidateLicenseOrdersQuota()
    invalidateAmpArticlesQuota()
  }, [
    refectAccount,
    refetchSubscription,
    queryClient,
    invalidateCampaignsQuota,
    invalidateLicenseOrdersQuota,
    invalidateAmpArticlesQuota,
  ])

  const handleConfirm = useCallback(async () => {
    if (!subscriptionPreview) return

    const updateSubscriptionParams: UpdateAccountPlanArgs = {
      existingAccountPlanId: existingPlanId,
      newAccountPlanId: subscriptionPreview?.newAccountPlanId,
      ...(subscriptionPreview.isUpgrade ? { prorationDate: subscriptionPreview.prorationDate } : {}),
    }

    try {
      if (isPaidPlan && paymentState.option === 'newCard') {
        if (!Stripe || !elements) return

        const cardElement = elements.getElement(CardElement)
        if (!cardElement) return

        setConfirmationState({ loading: true })

        const {
          data: { clientSecret },
        } = await createAccountPaymentMethod({ id: accountId })
        const confirmRes = await Stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        })

        const { setupIntent: { id: setupIntentId, payment_method: paymentMethodId } = {}, error: setupIntentError } =
          confirmRes

        if (setupIntentError || !setupIntentId || !paymentMethodId) {
          throw Error
        }

        await updateAccountPaymentMethod({
          id: accountId,
          setAsDefault: saveCard,
          setupIntentId,
          paymentMethodId: paymentMethodId as string,
        })

        await updateSubscription({
          ...updateSubscriptionParams,
          existingAccountPlanId: existingPlanId,
          newAccountPlanId: subscriptionPreview?.newAccountPlanId,
          prorationDate: subscriptionPreview.prorationDate,
          paymentMethodId: paymentMethodId as string,
        })
      } else {
        await updateSubscription(updateSubscriptionParams)
      }
      refetchAndInvalidateData()

      setConfirmationState({ loading: false })
      setPlanSuccessfulState({
        successType: subscriptionPreview.isUpgrade ? 'upgrade' : 'downgrade',
        ...(subscriptionPreview.isDowngrade ? { effective: subscriptionPreview.prorationDate * 1000 } : {}),
      })
    } catch (e) {
      setConfirmationState({ loading: false, error: true })
    }
  }, [
    subscriptionPreview,
    accountId,
    Stripe,
    elements,
    isPaidPlan,
    paymentState,
    saveCard,
    existingPlanId,
    setConfirmationState,
    updateSubscription,
    refectAccount,
    refetchSubscription,
    setPlanSuccessfulState,
    amountBilledPerMonth,
  ])

  return {
    saveCard,
    setSaveCard,
    paymentState,
    setPaymentState,
    subscriptionPreview,
    previewLoading,
    previewError,
    isPaidPlan,
    selectedPlanPrice,
    handleConfirm,
    confirmLoading: confirmationState.loading || isLoading,
    confirmError: confirmationState.error || isError,
    planSuccessfulState,
    amountBilledPerMonth,
  }
}
