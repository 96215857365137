import styled from 'styled-components'
import { Alert, Button, Card, CardContent } from '@mui/material'

import { Link } from '../sharedElements'

export const CardContainer = styled(Card)`
  text-align: center;
  height: 100%;
`

export const OptionContent = styled(CardContent)`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  align-items: center;
`

export const NetworkContent = styled(CardContent)`
  margin: 2% 5%;
  > * {
    margin-bottom: 24px;
  }
`

export const SelectButton = styled(Button)`
  &:disabled {
    color: #1976d2 !important;
  }
`

export const List = styled.ul`
  margin: 32px 0;
`

export const ListItem = styled.li`
  padding: 4px 0;
  text-align: left;
`

export const PriceContainer = styled.div`
  margin-top: auto;
`

export const FeatureLink = styled(Link)`
  color: #1976d2;
  &:hover {
    text-decoration: underline;
  }
`

export const LimitAlert = styled(Alert)`
  margin: 24px 0 !important;
`
