import { createContext, useState, useCallback, FC, useMemo, useEffect, useRef, Dispatch, SetStateAction } from 'react'
import { PlanType } from 'features/manage-plan/interfaces'

interface UpgradePlanProviderProps {
  isOpen: boolean
  openUpgradePlanDialog: (cb: CallableFunction, num: number) => void
  toggleUpgradePlanDialog: (numberToInclude?: number) => void
  handleSuccesfulUpgrade: () => void
  selectedPlan: PlanType
  setSelectedPlan: Dispatch<SetStateAction<PlanType>>
  plansToInclude: number
}

export const UpgradePlanContext = createContext({} as UpgradePlanProviderProps)

export const UpgradePlanProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<PlanType>(null)
  const [plansToInclude, setPlansToInclude] = useState<number>(0)
  const successCB = useRef<CallableFunction | null>(null)

  useEffect(() => {
    if (!isOpen) {
      successCB.current = null
      setTimeout(() => {
        setSelectedPlan(null)
        setPlansToInclude(0)
      }, 500)
    }
  }, [isOpen, successCB])

  const toggleUpgradePlanDialog = useCallback((numberPlans?: number) => {
    setIsOpen((o) => !o)
    if (numberPlans) {
      setPlansToInclude(numberPlans)
    }
  }, [])

  const openUpgradePlanDialog = useCallback(
    (upgradeSuccessCb: CallableFunction, numberPlans: number) => {
      toggleUpgradePlanDialog(numberPlans)
      successCB.current = upgradeSuccessCb
    },
    [toggleUpgradePlanDialog],
  )

  const handleSuccesfulUpgrade = useCallback(() => {
    if (successCB.current) {
      successCB.current()
    }
    toggleUpgradePlanDialog()
  }, [toggleUpgradePlanDialog, successCB])

  const value = useMemo(
    () => ({
      isOpen,
      openUpgradePlanDialog,
      toggleUpgradePlanDialog,
      handleSuccesfulUpgrade,
      selectedPlan,
      setSelectedPlan,
      plansToInclude,
    }),
    [
      isOpen,
      toggleUpgradePlanDialog,
      openUpgradePlanDialog,
      handleSuccesfulUpgrade,
      selectedPlan,
      setSelectedPlan,
      plansToInclude,
    ],
  )

  return <UpgradePlanContext.Provider value={value}>{children}</UpgradePlanContext.Provider>
}
