/**
 * @file Browser based logging util for the SPA and it's components
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import log, { getLogger } from 'loglevel'
import prefix from 'loglevel-plugin-prefix'

const forceLoggerLevel = process.env.REACT_APP_GLOBAL_LOG_LEVEL
const defaultLoggerLevel = process.env.REACT_APP_DEFAULT_LOG_LEVEL || 'info'

log.setLevel(forceLoggerLevel || defaultLoggerLevel)
prefix.reg(log)
prefix.apply(log, {
  format: (level, name, timestamp) => `[${timestamp}] ${level}${name ? ` [${name}]:` : ':'}`,
})

export const createLogger = (name) => {
  const newLoggerLogLevel = forceLoggerLevel || process.env[`REACT_APP_LOG_LEVEL_${name}`] || defaultLoggerLevel
  const newLogger = getLogger(name)
  newLogger.setLevel(newLoggerLogLevel)
  return newLogger
}
