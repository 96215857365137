import React, { createContext, useState } from 'react'

export const NotificationsContext = createContext({})

const NotificationsProvider = ({ children }) => {
  const [
    notificationsState,
    // setNotificationsState
  ] = useState({
    isLoading: true,
    error: null,
    notifications: [],
  })

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const notificationsRes = await API.Notifications.search({
  //         filters: [],
  //         sort: 'createdAt',
  //         order: 'desc',
  //         page: 0,
  //         resultsPerPage: 100,
  //       })
  //       setNotificationsState({
  //         isLoading: false,
  //         error: null,
  //         notifications: notificationsRes.items,
  //       })
  //     } catch (e) {
  //       console.error(e)
  //       setNotificationsState({
  //         isLoading: false,
  //         error: e,
  //         notifications: [],
  //       })
  //     }
  //   }

  //   fetchData()
  // }, [])

  return <NotificationsContext.Provider value={notificationsState}>{children}</NotificationsContext.Provider>
}

export default NotificationsProvider
