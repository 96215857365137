import { FC, useContext } from 'react'
import { Button } from '@mui/material'

import { ConfirmPlan } from 'features/manage-plan/components'
import { PlansOverview } from './plans-overview'
import { UpgradePlanContext } from 'providers/UpgradePlanProvider'

export const UpgradeFlow: FC = () => {
  const { handleSuccesfulUpgrade, selectedPlan, setSelectedPlan } = useContext(UpgradePlanContext)

  if (selectedPlan) {
    return (
      <ConfirmPlan setSelectedPlan={setSelectedPlan} planName={selectedPlan}>
        <Button variant="contained" onClick={handleSuccesfulUpgrade}>
          Close
        </Button>
      </ConfirmPlan>
    )
  }

  return <PlansOverview />
}
