import React, { createContext, useState, useCallback, useEffect, useMemo } from 'react'
import { getAccount } from '@dmlab/ui-api-accounts'
import { useFetchAccountPlanSubscription } from '@dmlab/ui-api-account-plan-subscriptions'
import { useSelector } from 'react-redux'
import { isEmpty } from '../utils/lodash'

export const AccountContext = createContext()

const AccountProvider = ({ children }) => {
  const user = useSelector((state) => state.userIdentity)
  const [accountState, setAccountState] = useState({
    data: null,
    error: null,
    loading: true,
    paymentMethodsAreEmpty: null,
    noBillingEmail: null,
    noBillingAddress: null,
  })

  const accountId = useMemo(() => user.accounts?.[0], [user])

  const {
    isLoading,
    accountPlanSubscription,
    isError,
    refetch: refetchSubscription,
  } = useFetchAccountPlanSubscription(accountId)

  const accountSubscription = useMemo(() => {
    const price = accountPlanSubscription?.activeSubscription.items.data[0].price
    const amountBilledPerMonth = price?.unitAmount / 100

    const upcomingPricing = (accountPlanSubscription?.upcomingPrice?.unitAmount || 0) / 100

    const { intervalCount, interval } = price?.recurring || {}

    return {
      accountPlanSubscription,
      price,
      activeSubscription: accountPlanSubscription?.activeSubscription,
      planLimits: accountPlanSubscription?.dmlAccountPlan.planAttributes,
      existingPlanId: accountPlanSubscription?.plan.metadata.planId,
      planName: accountPlanSubscription?.plan.name,
      upcomingPlanName: accountPlanSubscription?.upcomingPlan?.name,
      amountBilledPerMonth,
      billingInterval: intervalCount === 1 ? interval : `${intervalCount} ${interval}s`,
      upcomingPricing,
      upcomingAccountPlanId: accountPlanSubscription?.upcomingPlan?.metadata.planId,
      nextBillPricing: accountPlanSubscription?.upcomingPrice ? upcomingPricing : amountBilledPerMonth,
      isLoading,
      isError,
      dmlAccountPlan: accountPlanSubscription?.dmlAccountPlan,
    }
  }, [accountPlanSubscription, isLoading, isError])

  const fetchAccount = useCallback(async () => {
    setAccountState({
      data: null,
      error: null,
      loading: true,
      paymentMethodsAreEmpty: null,
      noBillingEmail: null,
      noBillingAddress: null,
    })

    try {
      const { data: account = {} } = await getAccount(user.accounts[0], {
        params: {
          include: ['billing', 'paymentMethods', 'parentAccount.name', 'childAccounts'],
        },
      })

      setAccountState({
        data: account,
        error: null,
        loading: false,
        paymentMethodsAreEmpty: isEmpty(account.paymentMethods),
        noBillingEmail: !account.billingEmail || account.billingEmail === '',
        noBillingAddress: isEmpty(account.billingAddress),
      })
    } catch (error) {
      setAccountState({
        data: null,
        error,
        loading: false,
        paymentMethodsAreEmpty: true,
        noBillingEmail: true,
        noBillingAddress: true,
      })
    }
  }, [user])

  useEffect(() => {
    fetchAccount()
  }, [fetchAccount])

  const accountLimits = useMemo(
    () => ({
      adCampaigns:
        typeof accountState.data?.netWorkLimits?.maxAdCampaigns === 'number'
          ? Number(accountState.data.netWorkLimits.maxAdCampaigns)
          : accountSubscription.planLimits?.maxAdCampaigns || 0,
      maxSeriesDownloads:
        typeof accountState.data?.netWorkLimits?.maxSeriesDownloads === 'number'
          ? Number(accountState.data.netWorkLimits.maxSeriesDownloads)
          : accountSubscription.planLimits?.maxSeriesDownloads || 0,
      maxAmpArticles:
        typeof accountState.data?.netWorkLimits?.maxAmpArticles === 'number'
          ? Number(accountState.data.netWorkLimits.maxAmpArticles)
          : accountSubscription.planLimits?.maxAmpArticles || 0,
    }),
    [accountState.data, accountSubscription.planLimits],
  )

  const value = {
    ...accountState,
    fetchAccount,
    accountSubscription,
    refetchSubscription,
    accountLimits,
  }

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
}

export default AccountProvider
