import { Dispatch, FC, SetStateAction } from 'react'
import format from 'date-fns/format'
import { Alert, Button, LinearProgress, Typography } from '@mui/material'

import { Payment } from './Payment'
import { PlanChangeSuccessful } from './PlanChangeSuccessful'
import { Header } from '../sharedElements'
import { usePayments } from './hooks'
import { upgradeText, downgradeText } from './constants'
import { PlanType } from '../../interfaces'
import { Divider, LineItem, ActionContainer, CircularProgress, PlanPaymentDescription } from './elements'

export interface Props {
  planName: PlanType | null
  setSelectedPlan: Dispatch<SetStateAction<PlanType>>
  handleReachedLimits?: (reachedLimits: boolean) => void
}

const convertToPrice = (stringifiedPrice?: string, digits: number = 0) => {
  if (!stringifiedPrice || Number(stringifiedPrice) === 0) return '$0'

  return Number(stringifiedPrice).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: digits,
  })
}

export const ConfirmPlan: FC<Props> = (props) => {
  const { planName, setSelectedPlan, children } = props
  const {
    saveCard,
    setSaveCard,
    paymentState,
    setPaymentState,
    subscriptionPreview,
    previewLoading,
    previewError,
    isPaidPlan,
    selectedPlanPrice,
    handleConfirm,
    confirmLoading,
    confirmError,
    planSuccessfulState,
    amountBilledPerMonth,
  } = usePayments(props)

  if (planSuccessfulState) {
    return <PlanChangeSuccessful {...planSuccessfulState}>{children}</PlanChangeSuccessful>
  }

  if (previewLoading) {
    return (
      <>
        <Header variant="h4">Confirm your new plan</Header>
        <CircularProgress />
      </>
    )
  }

  if (previewError || !subscriptionPreview) {
    return <Alert severity="error">A problem occurred while retrieving your subscription preview.</Alert>
  }

  const { isUpgrade, prorationDate, amountDue } = subscriptionPreview

  return (
    <>
      <Header variant="h4">Confirm your new plan</Header>

      <Divider />

      <LineItem>
        <Typography fontWeight={700}>Distributed Media Lab {planName} Plan</Typography>
        <Typography fontWeight={700}>{convertToPrice(selectedPlanPrice)}</Typography>
      </LineItem>

      {isPaidPlan && (
        <Typography>Billing monthly starting on {format(new Date(prorationDate * 1000), 'MMMM dd, yyyy')}</Typography>
      )}

      <LineItem>
        <Typography fontWeight={700}>
          Amount Due Today{isUpgrade && !!amountBilledPerMonth ? ' — Credit Applied' : ''}
        </Typography>
        <Typography fontWeight={700}>{convertToPrice((amountDue / 100).toString(), 2)}</Typography>
      </LineItem>

      <Divider />

      <PlanPaymentDescription>{isUpgrade ? upgradeText : downgradeText}</PlanPaymentDescription>

      {(isPaidPlan || amountDue !== 0) && (
        <Payment
          saveCard={saveCard}
          setSaveCard={setSaveCard}
          paymentState={paymentState}
          setPaymentState={setPaymentState}
        />
      )}

      <ActionContainer>
        <Button color="primary" disabled={confirmLoading} onClick={() => setSelectedPlan(null)}>
          Back
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={(isPaidPlan && !paymentState.completed) || confirmLoading}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </ActionContainer>

      {confirmError && <Alert severity="error">There was an issue confirming your new account plan</Alert>}
      {confirmLoading && <LinearProgress />}
    </>
  )
}
