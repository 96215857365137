import { useEffect } from 'react'
import ReactGA from 'react-ga'

/**
 * @argument {string | undefined} path
 * @argument {string | undefined} title
 * @argument {ReactGA.TrackerNames} trackerNames
 */
export const useRegisterPageView = (path, title, trackerNames = undefined) => {
  useEffect(() => {
    ReactGA.pageview(path, trackerNames, title)
  }, [])
}
