/**
 * @file Checks if the account has access to the marketplace
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { createContext, useState, useEffect, useContext } from 'react'
import { checkAccess } from '@dmlab/ui-api-access'
import { get, find } from '../utils/lodash'
import { AppConfigContext } from './AppConfigProvider'
import FullscreenBlinker from '../components/FullscreenBlinker'
import NoAccess from '../components/access/NoAccess'

export const AccessContext = createContext({})

const AccessProvider = (props) => {
  const { children } = props
  const { marketplaceId, marketplace } = useContext(AppConfigContext)
  const [configState, setConfigState] = useState({
    isLoading: true,
    hasAccess: null,
    userRequestedNotification: null,
    accessRequestPending: null,
  })
  const [fatalError, setFatalError] = useState(null)

  const { isLoading, hasAccess, accessRequestPending, userRequestedNotification } = configState

  useEffect(() => {
    if (marketplaceId) {
      const checkMarketplaceAccess = async () => {
        try {
          await checkAccess(`marketplace:${marketplaceId}`)
          setConfigState({
            hasAccess: true,
          })
        } catch (e) {
          const errors = get(e, 'response.data.errors', [])
          const noMarketplaceAccess = find(errors, { code: 'noMarketplaceAccess' })
          const accessRequestPending = find(errors, { code: 'accessRequestPending' })
          const userRequestedNotification = get(accessRequestPending, 'meta.userRequestedNotification', false)
          if (!noMarketplaceAccess && !accessRequestPending) {
            setFatalError(e)
          }
          setConfigState({
            accessRequestPending: !!accessRequestPending,
            userRequestedNotification,
          })
        }
      }
      checkMarketplaceAccess()
    }
  }, [marketplaceId])

  if (!hasAccess && !isLoading) {
    return (
      <NoAccess
        accessRequestPending={accessRequestPending}
        userRequestedNotification={userRequestedNotification}
        marketplace={marketplace}
      />
    )
  }

  if (!isLoading && !!fatalError) {
    return <p>Oops, there was an error loading the site.</p>
  }

  if (isLoading) return <FullscreenBlinker />

  const contextValue = {
    ...configState,
  }

  return <AccessContext.Provider value={contextValue}>{children}</AccessContext.Provider>
}

export default AccessProvider
