import { FC, Dispatch, SetStateAction } from 'react'
import { capitalize } from 'utils/lodash'
import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Typography, Checkbox } from '@mui/material'

import { cards } from 'assets/cards'
import { usePaymentForm } from './hooks'
import { PaymentState } from '../../interfaces'
import { CardElement, DefaultCardTypography, CardImage } from './elements'

const options = {
  style: {
    base: {
      letterSpacing: '0.08em',
    },
  },
}

interface Props {
  saveCard: boolean
  paymentState: PaymentState
  setSaveCard: Dispatch<SetStateAction<boolean>>
  setPaymentState: Dispatch<SetStateAction<PaymentState>>
}

export const Payment: FC<Props> = ({ saveCard, setSaveCard, setPaymentState, paymentState }) => {
  const { showCardElement, paymentMethodsAreEmpty, cardInfo, handleRadioChange, handleCardChange } = usePaymentForm({
    setPaymentState,
    paymentState,
  })

  return (
    <>
      <Typography variant="h6">Payment Details</Typography>

      {!paymentMethodsAreEmpty && cardInfo && (
        <FormControl>
          <RadioGroup value={paymentState.option} onChange={handleRadioChange}>
            <FormControlLabel
              label={
                <DefaultCardTypography>
                  Pay using stored card {capitalize(cardInfo.card.brand)} ending in {cardInfo.card.last4}
                  <CardImage src={cards[cardInfo.card.brand as keyof typeof cards]} />
                </DefaultCardTypography>
              }
              value="default"
              control={<Radio />}
            />
            <FormControlLabel label="Pay with different card" value="newCard" control={<Radio />} />
          </RadioGroup>
        </FormControl>
      )}

      {showCardElement && (
        <>
          <CardElement options={options} onChange={handleCardChange} />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={saveCard} onChange={(_, checked) => setSaveCard(checked)} />}
              label="Save as my new payment method"
            />
          </FormGroup>
        </>
      )}
    </>
  )
}
