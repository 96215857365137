import React, { useContext } from 'react'
import { ThemeProvider as MUI5ThemeProvider, createTheme as createMui5Theme } from '@mui/material/styles'
import { ThemeProvider as MUIThemeProvider, createTheme } from '@material-ui/core/styles'
import teal from '@material-ui/core/colors/teal'
import { AppConfigContext } from './AppConfigProvider'

const defaultOverrides = {
  typography: {
    fontFamily: ['Inter', 'Roboto', 'Arial', 'Helvetica', 'sans-serif !important'].join(','),
    root: {},
    h1: {
      fontFamily: 'DM Sans !important',
      fontSize: 34,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h2: {
      fontFamily: 'DM Sans !important',
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h3: {
      fontFamily: 'DM Sans !important',
      fontSize: 24,
      fontWeight: 500,
    },
    h4: {
      fontFamily: 'DM Sans !important',
      fontSize: 20,
      fontWeight: 500,
    },
    h5: {
      fontFamily: 'DM Sans !important',
      fontSize: 18,
      fontWeight: 500,
    },
    h6: {
      fontFamily: 'DM Sans !important',
      fontSize: 16,
      fontWeight: 500,
    },
  },
  overrides: {
    MuiBreadcrumbs: {
      root: {
        margin: '1em 0',
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: 'Inter !important',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#0065FF',
        color: '#FFF',
      },
    },
    MuiTypography: {
      displayBlock: {
        fontFamily: 'DM Sans !important',
      },
    },
  },
}

const ThemeProvider = ({ children }) => {
  const {
    marketplace: {
      config: { marketplacePrimaryColor = '#000' },
    },
  } = useContext(AppConfigContext)

  const themeMui4 = createTheme({
    palette: {
      primary: {
        main: '#0065FF',
      },
      secondary: teal,
      marketplacePrimary: marketplacePrimaryColor,
    },
    ...defaultOverrides,
  })

  const themeMui5 = createMui5Theme({
    palette: {
      primary: {
        main: '#0065FF',
      },
      secondary: teal,
      marketplacePrimary: marketplacePrimaryColor,
    },
    ...defaultOverrides,
  })

  return (
    <MUIThemeProvider theme={themeMui4}>
      <MUI5ThemeProvider theme={themeMui5}>{children}</MUI5ThemeProvider>
    </MUIThemeProvider>
  )
}

export default ThemeProvider
