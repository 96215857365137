/**
 * @file Marketplace AWS Exports
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

const isProdApi = process.env.REACT_APP_API === 'prod'
const isLocalApi = process.env.REACT_APP_API === 'local'
const devApiPath = process.env.REACT_APP_DEV_API || 'http://localhost:8080'

const useProdConfig = isProdApi && !isLocalApi

const region = useProdConfig ? 'us-east-1' : 'us-east-2'

const api = {
  name: 'DML-Publisher-API',
  description: '',
  ...(useProdConfig
    ? {
        id: 'z5pdlvwjli',
        stage: 'prod',
      }
    : {
        id: '5iobj8j9ha',
        stage: 'beta',
      }),
}

const cognito = useProdConfig
  ? {
      poolId: 'us-east-1_s24iR4EBL',
      webClientId: '6fpqqmhvq3l0a2ojbbc36h36mt',
      identityPoolId: 'us-east-1:21a150ea-e62d-42bc-8113-aa673dabdd3e',
    }
  : {
      poolId: 'us-east-2_wGC7Oqnr1',
      webClientId: '32h280tfp21n1jt50un85rajof',
      identityPoolId: 'us-east-2:d2cfd7c7-9c90-4b24-a58b-cb915ae1292e',
    }

const awsmobile = {
  Auth: {
    mandatorySignIn: true,
    region,
    userPoolId: cognito.poolId,
    identityPoolId: cognito.identityPoolId,
    userPoolWebClientId: cognito.webClientId,
  },
  API: {
    endpoints: [
      {
        ...api,
        region,
        endpoint: `https://${api.id}.execute-api.${region}.amazonaws.com/${api.stage}`,
        paths: ['/analytics', '/articles', '/collections', '/google-auth', '/properties', '/sites'],
      },
    ],
  },
  ...(isLocalApi
    ? {
        API: {
          endpoints: [
            {
              name: 'DML-Publisher-API',
              endpoint: devApiPath,
            },
          ],
        },
        aws_cloud_logic: null,
        aws_cloud_logic_custom: null,
      }
    : {}),
}

export default awsmobile
