/**
 * @file DML Marketplace Top Level File
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import './setupLogs' // sets default levels
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
// Redux setup
import { Provider } from 'react-redux'
import { createStore } from 'redux'
// Amplify API integration
import { Auth } from '@aws-amplify/auth'
// Analytics
import ReactGA from 'react-ga'
// Styles
import { create } from 'jss'
import { StylesProvider, createGenerateClassName, jssPreset } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import rootReducer from './reducers'
import AppConfigProvider from './providers/AppConfigProvider'
import ThemeProvider from './providers/ThemeProvider'
import awsExports from './aws-exports'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'animate.css'

// Initialize analytics
ReactGA.initialize('UA-111452396-10')

// Initialize Amplify
Auth.configure({
  ...awsExports,
  Analytics: {
    disabled: true,
  },
})

const queryClient = new QueryClient({
  // this causes multiple refreshes with modals for some reason
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5m
    },
  },
})

// Redux store
const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
// JSS namespace
const generateClassName = createGenerateClassName()
const jss = create(jssPreset())

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AppConfigProvider>
      <StylesProvider generateClassName={generateClassName} jss={jss}>
        <ThemeProvider>
          <CssBaseline />
          <Provider store={store}>
            <App />
          </Provider>
        </ThemeProvider>
      </StylesProvider>
    </AppConfigProvider>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
