import { FC } from 'react'
import { CardHeader, CircularProgress, Grid, Typography } from '@mui/material'

import { List, ListItem, CardContainer, PriceContainer, OptionContent, SelectButton, FeatureLink } from './elements'

interface Props {
  name: string
  features: string[]
  price?: string
  isCurrent?: boolean
  isLoading: boolean
  hasCustomPrice?: boolean
  customerPrice?: number
  handlePlanSelected: () => void
}

export const Option: FC<Props> = ({
  name,
  features,
  price,
  isCurrent,
  isLoading,
  hasCustomPrice,
  customerPrice,
  handlePlanSelected,
}) => (
  <Grid item xs={12} sm={6} md={3}>
    <CardContainer>
      <OptionContent>
        <CardHeader title={name} titleTypographyProps={{ component: 'h2', variant: 'h3', fontWeight: 500 }} />

        {isLoading && <CircularProgress />}
        {!isLoading && (
          <SelectButton
            variant={isCurrent ? 'text' : 'contained'}
            disabled={isCurrent}
            color="primary"
            onClick={handlePlanSelected}
          >
            {isCurrent ? 'Current Plan' : 'Select'}
          </SelectButton>
        )}

        <List>
          {features.map((text) => (
            <ListItem key={`${name}-${text}`}>
              <Typography variant="body2">{text}</Typography>
            </ListItem>
          ))}
          <ListItem>
            <Typography variant="body2">
              See{' '}
              <FeatureLink to={{ pathname: 'https://www.distributedmedialab.com/plans-and-pricing' }} target="_blank">
                full feature grid
              </FeatureLink>
            </Typography>
          </ListItem>
        </List>

        <PriceContainer>
          <Typography component="h2" variant="h1" fontWeight={500}>
            {price ? `$${price}/mo` : 'Free'}
          </Typography>
          {isCurrent && hasCustomPrice && (
            <Typography variant="h4" fontWeight={500}>
              You pay: ${customerPrice}/mo
            </Typography>
          )}
        </PriceContainer>
      </OptionContent>
    </CardContainer>
  </Grid>
)
