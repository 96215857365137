import { createContext, FC, useMemo, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useFetchQuotas, queryKeyQuotas, QuotaType, Quotas } from '@dmlab/ui-api-quotas'

import { ReduxState } from 'hooks/interfaces'

interface QuotasProviderProps {
  adCampaignsQuota: Quotas
  campaignsLoading: boolean
  campaignsError: boolean
  seriesLicenseOrdersQuota: Quotas
  licenseOrdersLoading: boolean
  licenseOrdersError: boolean
  ampArticleOrdersQuota: Quotas
  ampArticlesLoading: boolean
  ampArticlesError: boolean
  invalidateCampaignsQuota: () => void
  invalidateLicenseOrdersQuota: () => void
  invalidateAmpArticlesQuota: () => void
}

export const QuotasContext = createContext({} as QuotasProviderProps)

export const QuotasProvider: FC = ({ children }) => {
  const queryClient = useQueryClient()
  const accountId = useSelector<ReduxState, string>((state) => state.userIdentity.accountId || '')

  const {
    adCampaignsQuota,
    isLoading: campaignsLoading,
    isError: campaignsError,
  } = useFetchQuotas({ accountId, quotaType: 'adCampaigns' })
  const {
    seriesLicenseOrdersQuota,
    isLoading: licenseOrdersLoading,
    isError: licenseOrdersError,
  } = useFetchQuotas({ accountId, quotaType: 'seriesLicenseOrders' })
  const {
    ampArticleOrdersQuota,
    isLoading: ampArticlesLoading,
    isError: ampArticlesError,
  } = useFetchQuotas({ accountId, quotaType: 'ampArticleOrders' })

  const invalidateQuota = useCallback(
    (quotaType: QuotaType) => queryClient.invalidateQueries([queryKeyQuotas, { accountId, quotaType }]),
    [queryClient],
  )

  const invalidateCampaignsQuota = useCallback(() => invalidateQuota('adCampaigns'), [invalidateQuota])
  const invalidateLicenseOrdersQuota = useCallback(() => invalidateQuota('seriesLicenseOrders'), [invalidateQuota])
  const invalidateAmpArticlesQuota = useCallback(() => invalidateQuota('ampArticleOrders'), [invalidateQuota])

  const value = useMemo(
    () => ({
      adCampaignsQuota,
      campaignsLoading,
      campaignsError,
      seriesLicenseOrdersQuota,
      licenseOrdersLoading,
      licenseOrdersError,
      ampArticleOrdersQuota,
      ampArticlesLoading,
      ampArticlesError,
      invalidateCampaignsQuota,
      invalidateLicenseOrdersQuota,
      invalidateAmpArticlesQuota,
    }),
    [
      adCampaignsQuota,
      campaignsLoading,
      campaignsError,
      seriesLicenseOrdersQuota,
      licenseOrdersLoading,
      licenseOrdersError,
      ampArticleOrdersQuota,
      ampArticlesLoading,
      ampArticlesError,
      invalidateCampaignsQuota,
      invalidateLicenseOrdersQuota,
      invalidateAmpArticlesQuota,
    ],
  )

  return <QuotasContext.Provider value={value}>{children}</QuotasContext.Provider>
}
