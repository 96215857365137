/**
 * @file Re-usable section header comp
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import cx from 'classnames'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  sectionHeaderContainerLoading: {
    borderBottom: '1px solid #d8d8d8',
    margin: theme.spacing(3, 0.75, 1.5, 0.75),
  },
  sectionHeaderContainer: {
    borderBottom: '1px solid #000',
    margin: theme.spacing(3, 0.75, 1.5, 0.75),
  },
  sectionHeader: {
    textAlign: 'left',
    marginBottom: '.33em',
    display: 'flex',
  },
}))

const SectionHeader = (props) => {
  const { isLoading, label } = props

  const classes = useStyles(props)

  if (isLoading) {
    return (
      <div className={cx(classes.sectionHeaderContainerLoading)}>
        <Skeleton>
          <Typography variant="h4" component="h1" className={classes.sectionHeader}>
            {label}
          </Typography>
        </Skeleton>
      </div>
    )
  }

  return (
    <div className={cx(classes.sectionHeaderContainer)}>
      <Typography variant="h4" component="h1" className={classes.sectionHeader}>
        {label}
      </Typography>
    </div>
  )
}

export default SectionHeader
