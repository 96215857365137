/**
 * @file Top level app component if user is authenticated
 * @copyright Copyright 2021-present, Distributed Media Lab, Inc.
 */

import React, { useState, useEffect, useContext, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
// Stripe
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// Redux
import { useDispatch } from 'react-redux'
import { AppLayoutProvider } from '@dmlab/ui-app-layout'
import { updateAppMode, updateUser } from '@dmlab/ui-app-state'
import { ListViewProvider } from '@dmlab/ui-components-list-view'
// Amplify authentication
import { Auth } from '@aws-amplify/auth'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
// Internal
import { AppConfigContext } from './providers/AppConfigProvider'
import AccessProvider from './providers/AccessProvider'
import AccountProvider from './providers/AccountProvider'
import NotificationsProvider from './providers/NotificationsProvider'
import Footer, { footerHeight } from './components/Footer'
import FullscreenBlinker from './components/FullscreenBlinker'
import SubscriptionsProvider from './providers/SubscriptionsProvider'
import { UpgradePlanProvider } from './providers/UpgradePlanProvider'
import { QuotasProvider } from 'providers/QuotasProvider'
import { createTheme } from 'styles'

import Routes from './Routes'

const stripeKey = process.env.REACT_APP_STRIPE
const stripePromise = loadStripe(stripeKey, {
  betas: ['process_order_beta_1'],
  apiVersion: '2020-08-27; orders_beta=v3',
})

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: `${footerHeight}px`,
  },
  wrapper: {
    minHeight: '100vh',
    marginBottom: `-${footerHeight}px`,
    backgroundColor: '#fff',
  },
  betaBox: {
    fontSize: '10px',
    top: 0,
    right: 0,
    zIndex: '9999999',
    position: 'fixed',
    textTransform: 'uppercase',
    color: '#fff',
    opacity: 0.4,
    padding: theme.spacing(0.5, 1),
    background: '#404040',
  },
}))

const AppWithAuth = (props) => {
  const { onStateChange: handleStateChange } = props
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const {
    environment,
    marketplace: {
      config: { marketplacePrimaryColor = '#000' },
    },
  } = useContext(AppConfigContext)

  const theme = useMemo(() => createTheme(marketplacePrimaryColor), [marketplacePrimaryColor])

  const [loaded, setLoaded] = useState(false)
  const [authed, setAuthed] = useState(false)

  useEffect(() => {
    Auth.currentSession().then((userSession) => {
      dispatch(updateUser(userSession))
      dispatch(updateAppMode('marketplace'))
      setAuthed(true)
      setLoaded(true)
    })
  }, [dispatch])

  if (!loaded) {
    return <FullscreenBlinker />
  }

  if (!authed) {
    return (
      <section>
        Failed authorization. Please contact{' '}
        <a href="mailto:support@distributedmedialab.com">support@distributedmedialab.com</a>
      </section>
    )
  }

  // TODO: make this smarter with the above return functions
  return (
    <ThemeProvider theme={theme}>
      <AccessProvider>
        <AppLayoutProvider defaultExpanded>
          <Elements stripe={stripePromise}>
            {environment !== 'prod' && <div className={classes.betaBox}>{environment}</div>}
            <div className={classes.wrapper}>
              <ListViewProvider>
                <AccountProvider>
                  <QuotasProvider>
                    <SubscriptionsProvider>
                      <NotificationsProvider>
                        <UpgradePlanProvider>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Router>
                              <Routes handleStateChange={handleStateChange} />
                            </Router>
                          </MuiPickersUtilsProvider>
                        </UpgradePlanProvider>
                      </NotificationsProvider>
                    </SubscriptionsProvider>
                  </QuotasProvider>
                </AccountProvider>
              </ListViewProvider>
              <div className={classes.spacer} />
            </div>
            <Footer />
          </Elements>
        </AppLayoutProvider>
      </AccessProvider>
    </ThemeProvider>
  )
}

export default AppWithAuth
