import styled from 'styled-components'
import { Grid as MuiGrid, Card, DialogTitle as MuiDialogTitle, Typography } from '@mui/material'

export const EmailAnchor = styled.a`
  color: #1976d2;
  &:not(:hover) {
    text-decoration: none;
  }
`

export const Grid = styled(MuiGrid)`
  justify-content: center;
  margin-top: 8px;
`

export const BoldText = styled(Typography)`
  font-weight: 700 !important;
`

interface Props {
  $highlightBorder: boolean
}

export const CardContainer = styled(Card)<Props>`
  text-align: center;
  height: 100%;
  ${({ $highlightBorder }) => ($highlightBorder ? 'border: 1px solid #1976d2;' : '')}
`

export const DialogTitle = styled(MuiDialogTitle)`
  font-size: 1.5rem !important;
`
