/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

import { combineReducers } from 'redux'
import { userIdentity, page, appSettings } from '@dmlab/ui-app-state'

export default combineReducers({
  appSettings,
  page,
  userIdentity,
})
